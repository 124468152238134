<template>
<vx-card title="Promo Codes" :subtitle="`Records: ${promo.length}`" v-if="isValidPinCode">
    <template slot="actions">
        <div class="flex">
            <vs-select v-model="selectedStatus" name="state" @input="loadPromoCode" class="mr-2">
                <!-- <vs-select-item key="-1" value="" text="All" /> -->
                <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in status" />
            </vs-select>
            <vs-input v-model="searchString" class="mr-2" @keyup="handleFilter" placeholder="Search"></vs-input>
            <vs-button class="mr-2" color="success" @click="handleExport()">Export</vs-button>
            <vs-button @click="editSelected({})">Add</vs-button>
        </div>
        <EditPromo v-if="selectedUser" :selectedUser="selectedUser" :openAddWindow="openAddWindow" @loadPromoCode="loadPromoCode"></EditPromo>
    </template>
    <vs-table :sst="true" @sort="handleSort" stripe :data="promo" v-if="promo.length">
        <template #thead>
            <vs-th sort-key="promoCode">Promo Code</vs-th>
            <vs-th sort-key="discountPercentage">Discount</vs-th>
            <vs-th sort-key="status">Status</vs-th>
            <vs-th sort-key="expires">Expires</vs-th>
            <vs-th sort-key="productName">Product Name</vs-th>
            <vs-th sort-key="passNumber">Pass Number</vs-th>
            <vs-th sort-key="amountPaid">Amount Paid</vs-th>
            <vs-th sort-key="displayName">Display Name</vs-th>
            <vs-th sort-key="registrationNumber">Registration</vs-th>
            <vs-th class="width1"></vs-th>
        </template>
        <template>
            <vs-tr :key="i" v-for="(tr, i) in promo" :data="tr">
                <vs-td>
                    {{ tr.promoCode }}
                </vs-td>
                <vs-td>
                    {{ tr.discountPercentage | formatPercentage }}
                </vs-td>
                <vs-td>
                    <vs-chip v-show="tr.status === 'Pending'" color="warning">{{
              tr.status
            }}</vs-chip>
                    <vs-chip v-show="tr.status === 'Redeemed'" color="success">{{
              tr.status
            }}</vs-chip>
                </vs-td>
                <vs-td>
                    {{ tr.expiryDate | formatLongDate }}
                </vs-td>
                <vs-td>
                    {{
              tr.promoProductName == null
                ? "No Product Selected"
                : tr.promoProductName
            }}
                </vs-td>
                <vs-td>
                    {{ tr.passNumber }}
                </vs-td>
                <vs-td>
                    {{ tr.amountPaid }}
                </vs-td>
                <vs-td>
                    {{ tr.displayName }}
                </vs-td>
                <vs-td>
                    {{ tr.registrationNumber }}
                </vs-td>
                <vs-td>
                    <vs-button @click="removeUser(tr.id)" color="dark">Delete</vs-button>
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData>
</vx-card>
<div v-else>
    <vs-popup title="Access Restricted" :active.sync="isValidPincodePopup" @close="preventToClose" class="text-center" :button-close-hidden="true">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(validatePincode)" autocomplete="off">
                <div class="text-center">
                    <vs-row class="mb-base">
                        <ValidationProvider name="pincode" class="w-full ml-20 mr-20" :rules="{ regex: /^[0-9]+$/, required: true, max: 6,min:4 }" v-slot="{ errors }" :custom-messages="{required: customMessages.required, regex: customMessages.numberRegex  }">
                            <vs-input v-model="pincode" class="w-full input-center" style="font-size: medium" placeholder="Please Enter Pincode" @input="limitPincodeLength" />
                            <span class="text-danger text-sm">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </vs-row>
                    <div>
                        <vs-button class="mr-2" color="gray" @click="goBack()">Cancel</vs-button>
                        <vs-button class="mr-2" color="primary" @click="validatePincode()">Submit</vs-button>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </vs-popup>
</div>
</template>

<script>
import EditPromo from "./EditPromo";

import {
    promoData
} from "../../store/api/promo";
import _ from "lodash";
import {
    customMessages
} from "../../filters/validationmessage";

export default {
    data() {
        return {
            customMessages,
            pageNumber: 1,
            promo: [],
            selectedUser: undefined,
            openAddWindow: true,
            noData: {
                title: "No Records Found",
            },
            selectedStatus: "Pending",
            status: ["All", "Pending", "Redeemed"],
            searchString: "",
            isValidPinCode: false,
            isValidPincodePopup: true,
            pincode: "",
            maxLength: 6,
        };
    },
    components: {
        EditPromo,
    },
    mounted() {
        this.scroll();
    },
    async created() {
        // if (this.$store.getters.getValidatePromoPin) {
        //     this.isValidPinCode = true;
        //     this.isValidPincodePopup = false;
        //     return;
        // }
        await this.loadPromoCode();
    },
    methods: {
        preventToClose(value) {
            if (!value) this.isValidPincodePopup = true;
        },
        async validatePincode() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            const result = await promoData.validatePincode({
                pincode: this.pincode
            });

            if (!result.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: result.message,
                    color: "danger",
                    position: "top-center",
                });
                this.$store.dispatch("setValidatePromoPin", false);
                return;
            }
            this.pincode = "";
            this.isValidPinCode = true;
            this.isValidPincodePopup = false;
            this.$store.dispatch("setValidatePromoPin", true);
        },
        goBack() {
            this.$router.push("/admin/home");
        },
        limitPincodeLength() {
            if (this.pincode.length > this.maxLength) {
                this.pincode = this.pincode.slice(0, this.maxLength); // Trim excess characters
            }
        },
        scroll() {
            window.onscroll = async () => {
                if (
                    Math.ceil(document.documentElement.scrollTop) +
                    document.documentElement.clientHeight ===
                    document.documentElement.scrollHeight
                ) {
                    console.log(
                        "data checking",
                        Math.ceil(document.documentElement.scrollTop) +
                        document.documentElement.clientHeight,
                        "=",
                        document.documentElement.scrollHeight
                    );
                    this.pageNumber += 1;
                    await this.loadPromoCode();
                }
            };
        },
        async loadPromoCode() {
            var searchData = {
                status: this.selectedStatus,
                search: this.searchString,
                pageNumber: this.pageNumber,
            };

            const promoCodes = await promoData.searchByPage(searchData);

            if (this.pageNumber === 1) {
                this.promo = promoCodes;
            } else {
                promoCodes.forEach((element) => {
                    this.promo.push(element);
                });
            }

            if (this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
            }
        },

        async handleFilter() {
            this.pageNumber = 1;
            await this.loadPromoCode();
        },

        handleSort(key, active) {
            this.customers = _.orderBy(this.promo, key, active);
        },
        async removeUser(id) {
            if (confirm("are you sure ? ")) {
                var result = await promoData.removePromoCode(id);
                await this.loadPromoCode();

                this.$vs.notify({
                    title: "Success",
                    text: "Record Deleted",
                    color: "success",
                    position: "top-center",
                });
            }
        },
        async handleExport() {
            const result = await promoData.exportPromoCode(this.selectedStatus);
            if (result) {
                this.downloadItem(result.url);
            }
        },
        downloadItem(url) {
            const link = document.createElement("a");
            link.href = url;
            link.download;
            link.click();
            URL.revokeObjectURL(link.href);
        },
        editSelected(tr) {
            this.selectedUser = tr;
            if (!this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
            }
        },
    },
};
</script>

<style scoped>
.input-center ::v-deep input {
    text-align: center;
    line-height: normal;
    padding: 12px;
}
</style>
